import FacebookIconSvg from 'assets/svg/facebook.svg'
import InstagramIconSvg from 'assets/svg/instagram.svg'
import LinkedInIconSvg from 'assets/svg/linked-in.svg'
import ExternalLink from 'components/Common/ExternalLink'
import tw, { css, styled } from 'twin.macro'

const backgroundImageStyles = css([
  tw`bg-brand-primary-100`,
  tw`bg-[url('/images/lines-bottom-left.min.svg')]`,
  tw`md:bg-[url('/images/lines-bottom-left-md.min.svg'), url('/images/lines-top-right-md.min.svg')]`,
  tw`bg-no-repeat bg-[position:bottom_left, top_right]`,
  tw`md:fixed md:w-full md:h-full md:bg-contain`,
])

export const Background = styled.div([
  backgroundImageStyles,
  tw`h-screen flex justify-center items-center`,
])

export const Container = styled.div([
  tw`flex flex-wrap items-center justify-center`,
  tw`w-full mt-auto mb-5 relative`,
])

export const TermsAndPrivacyContainer = styled.div([
  tw`flex md:absolute md:right-6 text-sm justify-end`,
])

export const Copyright = styled.p([tw`hidden md:block`])

export const TermsAndPrivacyLink = styled(ExternalLink)([
  tw`text-brand-secondary-100 underline text-center cursor-pointer`,
  tw`hover:text-brand-secondary-80 duration-150`,
])

export const SocialMediaContainer = styled.div([
  tw`hidden md:flex gap-5 bottom-5 justify-center`,
])

export const SocialMediaText = styled.h1()

export const LinkedInIcon = styled(LinkedInIconSvg)(
  tw`h-6 w-6 hover:opacity-70`,
)
export const InstagramIcon = styled(InstagramIconSvg)(
  tw`h-6 w-6 hover:opacity-70`,
)
export const FacebookIcon = styled(FacebookIconSvg)(
  tw`h-6 w-6 hover:opacity-70`,
)
