import * as S from './styles'

export interface BackgroundProps {
  children: React.ReactNode
}

const Background = ({ children }: BackgroundProps) => {
  return <S.Background>{children}</S.Background>
}

export default Background
