/**
 *
 * Layout
 *
 */

import Head from 'next/head'
import { memo, ReactNode } from 'react'

export interface LayoutProps {
  title?: string
  children: ReactNode
}

export const Layout = ({ children, title = 'cRIDE' }: LayoutProps) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <main>{children}</main>
    </>
  )
}

export default memo(Layout)
