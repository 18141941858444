import ExternalLink from 'components/Common/ExternalLink'
import { Trans, useTranslation } from 'next-i18next'
import { ExternalRoutes } from 'routes'

import * as S from './styles'

const Footer = () => {
  const { t } = useTranslation(['auth'])
  return (
    <S.Container>
      <S.SocialMediaContainer>
        <S.SocialMediaText>{t('follow-us')}</S.SocialMediaText>
        <ExternalLink href={ExternalRoutes.LinkedIn} label="Linked In">
          <S.LinkedInIcon />
        </ExternalLink>
        <ExternalLink href={ExternalRoutes.Instagram} label="Instagram">
          <S.InstagramIcon />
        </ExternalLink>
        <ExternalLink href={ExternalRoutes.Facebook} label="Facebook">
          <S.FacebookIcon />
        </ExternalLink>
      </S.SocialMediaContainer>
      <S.TermsAndPrivacyContainer>
        <S.Copyright>
          <Trans components={{ bold: <strong /> }}>{t('copyright')}</Trans>
          &nbsp;-&nbsp;
        </S.Copyright>
        <S.TermsAndPrivacyLink
          href={ExternalRoutes.TermsAndConditions}
          label="Terms"
        >
          {t('terms-and-privacy-policy')}
        </S.TermsAndPrivacyLink>
      </S.TermsAndPrivacyContainer>
    </S.Container>
  )
}

export default Footer
