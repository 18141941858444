import tw, { css, styled } from 'twin.macro'

const backgroundImageStyles = css([
  tw`bg-brand-primary-100`,
  tw`bg-[url('/images/lines-bottom-left.min.svg')]`,
  tw`md:bg-[url('/images/lines-bottom-left-md.min.svg'), url('/images/lines-top-right-md.min.svg')]`,
  tw`bg-no-repeat bg-[position:bottom_left, top_right]`,
  tw`md:fixed md:w-full md:h-full md:bg-contain`,
])

export const Background = styled.div([
  backgroundImageStyles,
  tw`h-screen flex flex-wrap justify-center items-center`,
])
